module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"Essentiale","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":false,"database":false,"firestore":true,"storage":false,"messaging":false,"functions":false,"performance":false},"credentials":{"apiKey":"AIzaSyBsZ3U3PsdMg7_vDxu81hAAZTbEvtlzYxo","authDomain":"dw-essentiale-ankieta.firebaseapp.com","databaseURL":"https://dw-essentiale-ankieta.firebaseio.com","projectId":"dw-essentiale-ankieta","storageBucket":"dw-essentiale-ankieta.appspot.com","messagingSenderId":"1041141180000","appId":"1:1041141180000:web:6a22774dfd437599208505"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NM396CM","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NTJWPBS","includeInDevelopment":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
